import { useContext } from "react";
import { createContext, useState } from "react";

export const DeckRefContext = createContext(null);
export const DeckRefDispatchContext = createContext(null);

export function MapProvider({ children }) {
  const [deckRef, setDeckRef] = useState(null);
  return (
    <DeckRefContext.Provider value={deckRef}>
      <DeckRefDispatchContext.Provider value={setDeckRef}>
        {children}
      </DeckRefDispatchContext.Provider>
    </DeckRefContext.Provider>
  );
}

// TODO: use these instead of passing around as props
export function useDeckRef() {
  return useContext(DeckRefContext);
}

export function useSetDeckRef() {
  return useContext(DeckRefDispatchContext);
}
