import * as Sentry from "@sentry/nextjs";
import { createContext, useContext, useReducer } from "react";

export const AppContext = createContext(null);
export const AppDispatchContext = createContext(null);

const init = initialState => {
  return initialState;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "updatePollutant":
      return { ...state, pollutant: action.id };
    case "updateContract":
      return { ...state, contract: action.id };
    case "updateMetric":
      return { ...state, metric: action.id };
    default:
      Sentry.captureException(new Error("Unknown action type"));
  }
};

export function AppProvider({ children, initialState }) {
  const [appState, dispatch] = useReducer(reducer, initialState, init);

  return (
    <AppContext.Provider value={appState}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}

export function useAppState() {
  return useContext(AppContext);
}

export function useDispatch() {
  return useContext(AppDispatchContext);
}
