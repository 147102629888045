import {
  createImageUrlBuilder,
  createPortableTextComponent,
  createPreviewSubscriptionHook,
  createCurrentUserHook
} from "next-sanity";
import { config } from "./sanity.config";
import { blockContentToPlainText } from "react-portable-text";
/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 **/
export const urlFor = source => createImageUrlBuilder(config).image(source);

// Set up the live preview subscription hook
export const usePreviewSubscription = createPreviewSubscriptionHook(config);

// Set up Portable Text serialization
export const PortableText = createPortableTextComponent({
  ...config,
  // Serializers passed to @sanity/block-content-to-react
  // (https://github.com/sanity-io/block-content-to-react)
  serializers: {}
});

// Helper function for using the current logged in user account
export const useCurrentUser = createCurrentUserHook(config);

// Helper function to unpack blocks into plain text for a whole document
export const unpackBlocks = document => {
  return Object.entries(document).reduce((accum, [key, item]) => {
    if (item?.length === 1 && item[0]._type === "block") {
      accum[key] = blockContentToPlainText(item);
    } else {
      accum[key] = item;
    }
    return accum;
  }, {});
};
