import AirHealth from "icons/AirHealth";

export default function AirHealthLogo() {
  return (
    <div className="flex">
      <div className="hidden shrink-0 mr-4 md:block">
        <AirHealth size={328} />
      </div>
      <div className="flex flex-row-reverse gap-2 items-baseline md:hidden md:gap-0">
        <h4 className="text-base font-bold tracking-wide text-public-500">
          aclima
        </h4>
        <p className="mt-1 font-serif text-3xl tracking-wide text-public-500">
          Air Health
        </p>
      </div>
    </div>
  );
}
