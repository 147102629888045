import "components/styles.css";
import "components/mapbox-gl.css";
import Head from "next/head";
import Script from "next/script";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/react";
import { SWRConfig } from "swr";

import { appWithTranslation } from "next-i18next";
import { PlaceProvider } from "context/PlaceContext";
import { MapProvider } from "context/MapContext";
import { AppProvider } from "context/AppContext";

import React, { useEffect } from "react";
import reactDom from "react-dom";
import { useState } from "react";
import * as gtag from "lib/gtag";
import SharingMetatags from "components/SharingMetatags";
import AirHealth from "icons/AirHealth";
import { urlFor } from "lib/sanity";
import ErrorPage from "./ErrorPage";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [dataFilter, setDataFilter] = useState(null); //TODO remove in favor of `AppContext`

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      const axe = require("@axe-core/react");
      axe(React, reactDom, 1000);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = url => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const sharingImage =
    "image-08558917671c4b5d6cba386433d99150054ee6dc-1600x836-jpg";

  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `
        }}
      />
      {/* logo needs to be mounted at root for styles to be loaded correctly; TODO: fix */}
      <AirHealth size={0} />
      <SharingMetatags
        description="Look up air pollution and climate health information for any address in the Bay Area."
        title="Air Health by Aclima"
        imageUrl={urlFor(sharingImage).width(800).height(418).url()}
        imageAlt="Air Health preview image"
      />
      <Head>
        <title>air.health powered by Aclima</title>
        <meta charSet="utf-8"></meta>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1"></meta>
        <meta name="author" content="Aclima Inc."></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta httpEquiv="content-language" content="en"></meta>
        <meta name="robots" content="index, follow" />
      </Head>

      <Script src="https://aclimaservice.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-dtzt95/b/6/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=5b182129" />
      <AppProvider initialState={pageProps?.initialState}>
        <PlaceProvider>
          <MapProvider>
            <Sentry.ErrorBoundary
              fallback={({ error, resetError }) => (
                <ErrorPage message={error?.message} resetError={resetError} />
              )}
            >
              <SWRConfig
                value={{
                  onError: error => {
                    if (error.status !== 403 && error.status !== 404) {
                      Sentry.captureException(error);
                    }
                  }
                }}
              >
                <Component
                  dataFilter={dataFilter}
                  setDataFilter={setDataFilter}
                  {...pageProps}
                />
              </SWRConfig>
            </Sentry.ErrorBoundary>
          </MapProvider>
        </PlaceProvider>
      </AppProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
