import Head from "next/head";
import { useTranslation } from "react-i18next";

const DEFAULT_SHARING_TITLE = "air.health powered by Aclima";
const DEFAULT_IMAGE_ALT = "Map of Study Area";
const DEFAULT_IMG_HEIGHT = 800;
const DEFAULT_IMG_WIDTH = 418;

export const createDescription = pageName =>
  `Visualize air quality block-by-block. ${pageName.toProperCase()}`;

/**
 * Needs to sit outside of next's Head Component
 */
const SharingMetatags = ({
  imageUrl,
  description,
  platform,
  url = "https://www.aclima.io",
  imageHeight = DEFAULT_IMG_HEIGHT,
  imageWidth = DEFAULT_IMG_WIDTH,
  title = DEFAULT_SHARING_TITLE,
  imageAlt = DEFAULT_IMAGE_ALT
}) => {
  const { t } = useTranslation();

  const translatedDescription = t(`${platform || "fb"}-share-description`);

  // if we just get the translation key back, use prop description
  const platformDescription = translatedDescription.includes(
    "-share-description"
  )
    ? description
    : translatedDescription;

  return (
    <Head>
      <meta property="og:url" content={url} key="ogurl"></meta>
      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:type" content="website" key="ogtype" />
      <meta property="twitter:title" content={title} key="twittertitle" />
      <meta
        property="og:description"
        content={platformDescription}
        key="ogdescription"
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twittercard"
      ></meta>
      <meta property="twitter:image" content={imageUrl} key="twitterimage" />
      <meta
        property="twitter:image:alt"
        content={imageAlt}
        key="twitterimagealt"
      />
      <meta
        property="twitter:description"
        content={platformDescription}
        key="twitterdescription"
      />
      <meta property="og:image" content={imageUrl} key="ogimage" />
      <meta property="og:image:type" content="image/jpeg" key="ogimagetype" />
      <meta property="og:image:width" content={imageWidth} key="ogimagewidth" />
      <meta
        property="og:image:height"
        content={imageHeight}
        key="ogimageheight"
      />
      <meta property="og:image:alt" content={imageAlt} key="ogimagealt" />
    </Head>
  );
};

export default SharingMetatags;
