import { useContext } from "react";
import { createContext, useState } from "react";

export const PlaceContext = createContext(null);
export const PlaceDispatchContext = createContext(null);
export const PlaceReportPageContext = createContext(null);
export const PlaceReportPageDispatchContext = createContext(null);

export function PlaceProvider({ children }) {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [reportPage, setReportPage] = useState(null);
  return (
    <PlaceContext.Provider value={selectedPlace}>
      <PlaceDispatchContext.Provider value={setSelectedPlace}>
        <PlaceReportPageContext.Provider value={reportPage}>
          <PlaceReportPageDispatchContext.Provider value={setReportPage}>
            {children}
          </PlaceReportPageDispatchContext.Provider>
        </PlaceReportPageContext.Provider>
      </PlaceDispatchContext.Provider>
    </PlaceContext.Provider>
  );
}

// TODO: use these instead of passing around as props

export function useSelectedPlace() {
  return useContext(PlaceContext);
}

export function useSetSelectedPlace() {
  return useContext(PlaceDispatchContext);
}
export function useSelectedPlaceReportPage() {
  return useContext(PlaceReportPageContext);
}

export function useSetSelectedPlaceReportPage() {
  return useContext(PlaceReportPageDispatchContext);
}
